import { memo, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Col, Icon, Paragraph1, Paragraph2, Row } from "../../_components";
import { baseCard } from "../../_theme/general";

const Toast = ({ show, onHide, children, timeShown = 10 }) => {
  const [seconds, setSeconds] = useState(timeShown);

  useEffect(() => {
    if (timeShown && show) {
      const timer = setTimeout(() => {
        seconds > 0 && setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [timeShown, seconds, show]);

  const handleDismiss = useCallback(() => {
    setSeconds(timeShown);
    onHide && onHide();
  }, [timeShown, setSeconds, onHide]);

  useEffect(() => {
    if (seconds <= 0) {
      handleDismiss();
    }
  }, [handleDismiss, seconds]);

  if (!show) return null;

  return (
    <Wrapper>
      <Col md={10}>{children}</Col>
      <Col md={2}>
        <DismissText onClick={handleDismiss}>Dismiss</DismissText>
      </Col>
    </Wrapper>
  );
};

export const ToastApproval = ({
  show,
  onHide,
  title,
  icon = "check-circle",
}) => {
  return (
    <Toast show={show} onHide={onHide}>
      <Row $align="center">
        <Col xs="auto">
          <Icon name={icon} />
        </Col>
        <Col>
          <Paragraph1 $ml={3}>{title}</Paragraph1>
        </Col>
      </Row>
    </Toast>
  );
};

const Wrapper = styled(Row)`
  ${baseCard};
  max-width: 600px;
  align-items: center;
  position: fixed;
  z-index: 3;
  top: 7.5rem;
  right: 2rem;
`;

const DismissText = styled(Paragraph2)`
  cursor: pointer;
  font-weight: 700;
`;

export default memo(Toast);
