import styled from "styled-components";
import moment from "moment";
import { utcDateToDisplay } from "../../../_utils";
import { Icon, Paragraph2, Paragraph3, Paragraph4, Row } from "../..";
import FavoriteACourse from "../../FavoriteACourse";

const HeaderOverlay = ({ live, event, data }) => {
  return (
    <>
      <Row
        $justify={live || event ? "space-between" : "flex-end"}
        $align={"center"}
        $mx={2}
        $mb={2}
      >
        {live ? (
          <>
            <MetaLiveContent>
              <Icon name="live" width="1.2em" height="1.2em" />
              <Paragraph4 color="white" $pl={1} weight={700}>
                {data?.schedule?.type === "live_open_door"
                  ? "LIVE OPEN DOOR"
                  : "LIVE"}
              </Paragraph4>
            </MetaLiveContent>

            <MetaItem $justify="center" $align="center">
              <Paragraph4 color="white" $pl={1} weight={700}>
                Est. {data?.schedule?.duration_estimate}m
              </Paragraph4>
            </MetaItem>
          </>
        ) : event ? (
          <>
            <MetaLiveContent>
              <Icon
                name={event?.icon}
                fill="white"
                width="1.2em"
                height="1.2em"
              />
              <Paragraph4
                color="white"
                $pl={1}
                weight={700}
                textAlign="center"
                $textTransform="uppercase"
              >
                {event?.type}
              </Paragraph4>
            </MetaLiveContent>

            <MetaItem $justify="center" $align="center">
              <Paragraph4 color="white" weight="700">
                Est. {event?.duration}m
              </Paragraph4>
            </MetaItem>
          </>
        ) : null}
        {data?.is_viewed && (
          <LastViewedWrapper>
            <LastViewed>
              <Icon
                name="check"
                fill={"green"}
                height="1.2rem"
                width="1.2rem"
              />
              <Paragraph3 color={"blue"} weight="700" $ml={4}>
                Last Viewed {moment(data?.is_viewed).format("MM/D/YY")}
              </Paragraph3>
            </LastViewed>
          </LastViewedWrapper>
        )}
        {data?.duration_mins && !data?.schedule?.duration_estimate && !event ? (
          <MetaItem $justify="center" $align="center">
            <Paragraph2 color="white">{data?.duration_mins}m</Paragraph2>
          </MetaItem>
        ) : null}
      </Row>
      {event?.type !== "Program" && (
        <BookmarkWrapperWithTop>
          <FavoriteACourse
            course={data}
            favoriteCallback={() => null}
            showShare
          />
        </BookmarkWrapperWithTop>
      )}
    </>
  );
};

export const HeaderOverlayTopClassTime = ({
  live,
  data,
  favoriteCallback,
  hideFavorite,
}) => {
  const date = live ? utcDateToDisplay(data?.schedule?.date_schedule) : "";
  return (
    <>
      {live && (
        <Row $justify="center" $pt={2}>
          <MetaLiveDateText>
            {moment(date).format("ddd, M/D • h:mm A")}
          </MetaLiveDateText>
        </Row>
      )}
      {!live && !hideFavorite && (
        <BookmarkWrapper>
          <FavoriteACourse
            course={data}
            favoriteCallback={favoriteCallback}
            showShare
          />
        </BookmarkWrapper>
      )}
    </>
  );
};

const LastViewedWrapper = styled(Row)`
  border-radius: 4px;
  padding: 4px;
  width: auto;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(30px);
  // 30 pixel is the width of the meta items
`;

const LastViewed = styled(Row)`
  padding: 0.25rem;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.baseYellow};
`;

const MetaItem = styled(Row)`
  background-color: ${({ theme }) => theme.palette.neutralDarkestGrey};
  border-radius: 4px;
  padding: 4px;
  width: auto;
  align-items: center;
`;

const MetaLiveContent = styled(Row)`
  align-items: flex-end;
  width: auto;
`;

const MetaLiveDateText = styled(Paragraph4)`
  color: ${({ theme }) => theme.palette.neutralWhite};
  font-weight: 700;
  margin-left: ${({ theme }) => theme.components.card.spacing}px;
`;

const BookmarkWrapper = styled(Row)`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
`;

const BookmarkWrapperWithTop = styled(Row)`
  position: fixed;
  top: 0.8rem;
  right: 0.8rem;
`;

export default HeaderOverlay;
