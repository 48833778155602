import React, { useState } from "react";
import styled from "styled-components";
import { Paragraph2 } from "..";
import Icon from "../Icon/Icon";

const CircleOverlay = ({
  onClick,
  isClicked,
  className = "",
  pressedValue,
  value,
  iconName,
  text,
  subtitle,
  textSize,
  multiSelect,
  width = "6",
}) => {
  const [clicked, setClick] = useState(isClicked);

  const hasOnClick = onClick && typeof onClick === "function";

  const handleOnClick = () => {
    if (hasOnClick) {
      onClick(value);
    }
    if (multiSelect) {
      setClick(!clicked);
    }
  };

  return (
    <div
      className={`d-inline-flex ${className}`}
      onClick={handleOnClick}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleOnClick();
        }
      }}
    >
      <WrapperOverlay
        clicked={clicked || pressedValue === value}
        width={width}
        id={`${text}-${value}-${clicked ? "selected" : "unselected"}`}
      >
        {(pressedValue === value || clicked) && (
          <IconOverlay name={`check`} width={width} color="white" />
        )}
        {iconName ? (
          <IconUnderlay
            clicked={clicked || pressedValue === value}
            width={width}
            name={iconName}
            color="white"
          />
        ) : text ? (
          <TextInBackground textSize={textSize}>{text}</TextInBackground>
        ) : null}
      </WrapperOverlay>
      {subtitle ? (
        <Paragraph2>
          <strong>{subtitle}</strong>
          <Paragraph2>
            {pressedValue === value || clicked
              ? value?.secondarySubtitle
              : value?.subtitle}
          </Paragraph2>
        </Paragraph2>
      ) : null}
    </div>
  );
};

const WrapperOverlay = styled.div`
  height: ${(props) => props.width + "rem"};
  width: ${(props) => props.width + "rem"};
  color: ${({ theme }) => theme.palette.primaryBlue};
  border: ${({ theme }) => "4px solid " + theme.palette.primaryBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.clicked ? "rgba(0, 36, 72, 0.6)" : "")};
`;

const IconOverlay = styled(Icon)`
  width: ${(props) => props.width * 0.59 + "rem"};
  height: ${(props) => props.width * 0.59 + "rem"};
  color: white;
  opacity: 0.95;
  position: absolute;
`;

const IconUnderlay = styled(Icon)`
  width: ${(props) => props.width * 0.59 + "rem"};
  height: ${(props) => props.width * 0.59 + "rem"};
  color: ${(props) => (props.clicked ? "darken(#06a4ac, 20%)" : "")};
`;

const TextInBackground = styled.div`
  font-size: ${(props) => (props.textSize ? props.textSize + "px" : "16px")};
`;

export default CircleOverlay;
