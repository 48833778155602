import { useMemo } from "react";
import styled from "styled-components";
import { Paragraph1, Paragraph4 } from "../../Typography";
import { Col } from "../../Layout";
import { lineClamp, ellipsis } from "../../../_theme/general";
import Avatar from "../../ui/Avatars/Avatar";
import { useMedia } from "../../../_hooks";

const LiveClassOverlay = ({ live, data, slider }) => {
  const isSmall = useMedia("(min-width: 576px)");
  const isMedium = useMedia("(min-width: 768px)");
  const isLarge = useMedia("(min-width: 1200px)");
  const name = useMemo(
    () => `${data?.instructor?.first_name} ${data?.instructor?.last_name}`,
    [data?.instructor]
  );
  const hideInstructor = useMemo(
    () =>
      (slider && isSmall && isMedium && !isLarge) ||
      (!slider && (isSmall || isMedium)),
    [slider, isSmall, isMedium, isLarge]
  );

  const amt = useMemo(
    () => (slider && (isLarge || (isSmall && !isMedium)) ? 1 : 2),
    [slider, isSmall, isMedium, isLarge]
  );

  if (!live) return null;

  return (
    <Col $align="center" $justify="center">
      <Avatar src={data?.instructor?.profile?.file_avatar} white $mb={1} />
      {!hideInstructor && (
        <>
          <Paragraph4 color="white" textAlign="center">
            Instructor
          </Paragraph4>
          <Title color="white" $amt={amt}>
            {name}
          </Title>
        </>
      )}
    </Col>
  );
};

const Title = styled(Paragraph1)`
  ${lineClamp}
  ${ellipsis}
  text-align: center;
`;

export default LiveClassOverlay;
