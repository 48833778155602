import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import { useLocation, useNavigate } from "react-router-dom";

import { ClassMenuWrapper, MenuItem } from "./Menu";

import { useGetMenuItems } from "./_utils";

const MainMenuDropdownToggle = React.forwardRef(({ title, onClick }, ref) => (
  <Nav.Link
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="pl-0"
  >
    {title}
  </Nav.Link>
));

const ClassMenu = ({ menu }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const coursesSubMenuItems = useGetMenuItems(menu);
  return (
    <ClassMenuWrapper>
      {coursesSubMenuItems?.map((item, index) => {
        return !item?.hide ? (
          <MenuItem
            key={`nav-course-key-${index}`}
            active={pathname === item.link}
            onClick={() =>
              navigate(item.link, { state: { from: "main_menu_dropdown" } })
            }
            id={`${item.label.replaceAll(" ", "-").toLowerCase()}-mm-dd-link`}
          >
            {item.label}
          </MenuItem>
        ) : null;
      })}
    </ClassMenuWrapper>
  );
};

const ClassNavDropdown = ({ children, menu }) => {
  return (
    <Dropdown $align="end">
      <Dropdown.Toggle
        id="userAvatarDropdown"
        as={MainMenuDropdownToggle}
        title={children}
      />
      <ClassMenu menu={menu} />
    </Dropdown>
  );
};

export default ClassNavDropdown;
