import {
  SCHEDULE_LOG,
  SCHEDULE_RESET,
} from "../../reducers/notifications/schedule";

export const logDismissed = (dismissed) => {
  return {
    type: SCHEDULE_LOG,
    dismissed,
  };
};

export const resetDismissed = () => ({
  type: SCHEDULE_RESET,
});
