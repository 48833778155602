import { memo } from "react";
import styled from "styled-components";

import { ROUTES } from "../../../_routes";
import {
  Avatar,
  Link,
  Row,
  Paragraph2,
  Paragraph3,
} from "../../../_components";

const UserNavSideBarSection = ({ show, userData }) => {
  if (!show) return null;
  return (
    <Link
      to={`${ROUTES.PSS}${ROUTES.PROFILE}`}
      id={`pss-${userData?.first_name}-nav-sidebar`}
    >
      <RowWrapper>
        <Avatar
          src={userData?.file_avatar}
          alt={`${userData?.first_name} ${userData?.last_name}`}
        />

        <NameWrappr>
          <Paragraph2 weight="700" $ellipsis $amt={1} color="white">
            {userData?.organization?.title}
          </Paragraph2>
          <Paragraph3 $ellipsis $amt={1} color="white">
            {`${userData?.first_name} ${userData?.last_name}`}
          </Paragraph3>
        </NameWrappr>
      </RowWrapper>
    </Link>
  );
};

const NameWrappr = styled.div`
  padding-left: 0.5rem;
  width: calc(100% - 3rem);
`;

const RowWrapper = styled(Row)`
  background-color: ${({ theme }) => theme.palette.primaryBlue};
  flex-wrap: nowrap;
  align-items: center;
  padding: 1rem;
`;

export default memo(UserNavSideBarSection);
