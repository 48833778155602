import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Icon, Separator } from "../../_components";
import { Menu, MenuItem, LinkText } from "./Menu";

import { doLogoutAsync } from "../../_redux/actions/auth/doLogoutAsync";
import { useGetMenuItems } from "./_utils";

const ProfileButtonToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    ref={ref}
    role="button"
    aria-label={"user menu"}
    style={{ textAlign: "end" }}
    id="user-dropdown-container"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    onKeyPress={(e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        onClick(e);
      }
    }}
  >
    <Icon
      aria-label="user menu toggle"
      tabIndex={0}
      name="profile"
      height="56px"
      width="56px"
    />
  </div>
));

const UserMenu = ({ doLogout }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userSubMenuItems = useGetMenuItems("profile");

  const handleOnClick = (item) => {
    item?.href
      ? window.open(item?.href, "_blank").focus()
      : navigate(item.link, { state: { from: "user_dropdown" } });
  };

  const logout = () => doLogout(navigate);

  return (
    <Menu align={"end"}>
      {userSubMenuItems?.map((item, index) => {
        return item?.hide ? null : item?.line ? (
          <Separator
            centered
            $width={"80%"}
            $my={1}
            key={`profile-menu-${index}`}
          />
        ) : (
          <MenuItem
            key={`profile-menu-${index}`}
            active={pathname === item.link}
            onClick={() => handleOnClick(item)}
            id={`${item.label.replaceAll(" ", "-").toLowerCase()}-ud-dd-link`}
          >
            <LinkText $active={pathname === item.link}>{item.label}</LinkText>
          </MenuItem>
        );
      })}
      <MenuItem onClick={logout} id="logout-ud-dd-link">
        <LinkText>Logout</LinkText>
      </MenuItem>
    </Menu>
  );
};

const UserDropdown = ({ doLogout, hide, handleOpenMenu, handleCloseMenu }) => {
  const handleOnBlur = (e) => {
    e.preventDefault();
    handleCloseMenu(e);
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (
      e.currentTarget.id === "userAvatarDropdown" &&
      !e.currentTarget.className.includes("show")
    ) {
      handleOpenMenu(e);
    } else handleCloseMenu(e);
  };

  return (
    <DropdownWrapper
      id="userAvatarDropdown"
      $hide={hide}
      onBlur={handleOnBlur}
      onClick={handleOnClick}
    >
      <Dropdown.Toggle as={ProfileButtonToggle} $hide={hide} />
      <UserMenu doLogout={doLogout} />
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled(Dropdown)`
  position: fixed;
  top: 16px;
  right: 34px;
  display: ${({ $hide }) => $hide && "none"};
`;

export default connect(null, { doLogout: doLogoutAsync })(UserDropdown);
