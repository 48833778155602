import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { authAxios } from "../_utils";
import { datadogRum } from '@datadog/browser-rum';

const ConsentsContext = createContext();

export const useConsents = () => useContext(ConsentsContext);

export const ConsentsProvider = ({ children }) => {
  const [consents, setConsents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  const getConsents = useCallback(async () => {
    setLoading(true);
    try {
      const res = await authAxios.get("/consents/user");
      if (res.status !== 200) {
        const error = new Error('Unable to fetch consents');
        datadogRum.addError(error, { response: res });
      } else if (res.data.length === 0) {
        const error = new Error('Empty consents data');
        datadogRum.addError(error, { response: res });
      };
      setConsents(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    setInitialLoading(true);
    getConsents();
  }, [getConsents]);

  /* consents takes this shape:
  [
    {
      consent_id: consentId,
      consent_choice: consentChoice,
    },
  ]
  */
  const updateConsents = async (consents) => {
    setLoading(true);
    try {
      res = await authAxios.post("consents/user/", consents);
      if (res.status !== 200) {
        const error = new Error('Unable to update consents');
        datadogRum.addError(error, { response: res });
      };

      await getConsents();
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const value = { consents, loading, initialLoading, updateConsents };

  return (
    <ConsentsContext.Provider value={value}>
      {children}
    </ConsentsContext.Provider>
  );
};
