import { useRef, useState, useEffect, useCallback } from "react";

/*
  Arguments (both optional):
  {startSeconds} = what time the timer begins at in seconds
  {updateInterval} = how long the time is set for updates (default is 1 second)
*/

const useTimer = (startSeconds = 0, updateInterval = 1) => {
  const intervalRef = useRef(null);
  const [elapsed, setElapsed] = useState(startSeconds);

  const clear = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setElapsed(0);
    }
  }, []);

  useEffect(() => {
    if (startSeconds > 0) {
      setElapsed(startSeconds);
    }
    return () => clear();
  }, [startSeconds, clear]);

  const reset = useCallback(() => {
    clear();
    setElapsed(0);
  }, [clear]);

  const start = useCallback(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(
        () => setElapsed((elapsedTime) => elapsedTime + updateInterval),
        1000 * updateInterval
      );
    }
  }, [updateInterval]);

  const restart = useCallback(() => {
    reset();
    start();
  }, [reset, start]);

  return {
    elapsed,
    elapsedFormatted: new Date(elapsed * 1000).toISOString().substr(11, 8),
    start,
    stop: clear,
    reset,
    restart,
  };
};

export default useTimer;
