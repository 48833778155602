import {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { authAxios } from "../_utils";

// List of features that require user to go to payment screen
export const digitalFreeStr = "DF"; // Digital Free Access
export const directPaidStr = "DP"; // Direct Paid Digital Access
export const plnStr = "PLN"; // Legacy Burnalong Prime/Plus Access
export const baPlusStr = "BA+"; // Burnalong Prime/Plus Access
export const subHasBAPlusStr = "BASUB"; // Subaccounts Burnalong Prime/Plus Access

export const burnalongPrimeFeature = [plnStr, baPlusStr];

const OrgProFeaturesContext = createContext([]);

const OrgProFeaturesContextProvider = ({ children }) => {
  const [orgProFeaturesList, setOrgProFeaturesList] = useState([]);
  const [loading, setLoading] = useState(true);

  // Check if PLN exists in orgProFeaturesList
  // Remove, once off-boarded PLN
  const orgHasPLNFeature = useMemo(() => orgProFeaturesList.includes(plnStr), [
    orgProFeaturesList,
  ]);

  // Check if DF exists in orgProFeaturesList
  const orgHasDigitalFreeFeature = useMemo(
    () => orgProFeaturesList.includes(digitalFreeStr),
    [orgProFeaturesList]
  );

  // Check if DP exists in orgProFeaturesList
  const orgHasPaidDigitalFeature = useMemo(
    () => orgProFeaturesList.includes(directPaidStr),
    [orgProFeaturesList]
  );

  // Check if either BA+ or PLN exists in orgProFeaturesList
  const orgHasPlusFeature = useMemo(
    () =>
      orgProFeaturesList.some((feature) =>
        burnalongPrimeFeature.includes(feature)
      ),
    [orgProFeaturesList]
  );

  // CHeck if BASUB exists in orgProFeaturesList
  const orgHasSubBAPlusFeature = useMemo(
    () => orgProFeaturesList.includes(subHasBAPlusStr),
    [orgProFeaturesList]
  );

  const getOrgProFeatures = useCallback(() => {
    setLoading(true);
    authAxios
      .post("/organizationprofeatures/")
      .then((res) => {
        setOrgProFeaturesList(res.data.data.orgprofeatures);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // Fetch org pro features
    getOrgProFeatures();
  }, [getOrgProFeatures]);

  const contextValues = useMemo(
    () => ({
      orgProFeaturesList,
      loading,
      fetch: getOrgProFeatures,
      orgPaidFeatures: {
        orgHasDigitalFreeFeature,
        orgHasPaidDigitalFeature,
        orgHasPlusFeature,
        orgHasSubBAPlusFeature,
        orgHasPLNFeature,
      },
    }),
    [
      orgProFeaturesList,
      loading,
      getOrgProFeatures,
      orgHasDigitalFreeFeature,
      orgHasPaidDigitalFeature,
      orgHasPlusFeature,
      orgHasSubBAPlusFeature,
      orgHasPLNFeature,
    ]
  );

  return (
    <OrgProFeaturesContext.Provider value={contextValues}>
      {children}
    </OrgProFeaturesContext.Provider>
  );
};

const useOrgProFeatures = () => {
  const context = useContext(OrgProFeaturesContext);
  if (!context) {
    throw new Error("Context ProFeature must be used within a Provider");
  }
  return context;
};

export {
  useOrgProFeatures,
  OrgProFeaturesContext,
  OrgProFeaturesContextProvider,
};
