export const UI_THEME = "ui/THEME";
export const UI_FONT_SIZE = "ui/FONT_SIZE";

const darkMode =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const savedFontSize = localStorage.getItem("ui-font-size");
const savedTheme = localStorage.getItem("ui-theme");

const initialState = {
  fontSize: savedFontSize || "sm",
  theme: savedTheme ? savedTheme : darkMode ? "dark" : "light",
};
export const accessibility = (state = initialState, action) => {
  const { fontSize, theme } = action;

  switch (action.type) {
    case UI_FONT_SIZE:
      localStorage.setItem("ui-font-size", fontSize);
      return {
        ...state,
        fontSize,
      };
    case UI_THEME:
      localStorage.setItem("ui-theme", theme);
      return {
        ...state,
        theme,
      };
    default:
      return state;
  }
};

export default accessibility;
