import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";

import { ROUTES } from "../../_routes";
import { useAuthAxios, useMedia } from "../../_hooks";
import { finderShowOnMobile } from "../../_redux/actions/search/finder";
import { Col, Paragraph1, Row, Link } from "../../_components";

import { Hyperlink } from "../../_components/ui";
import { protocolUrl } from "../../_utils";

const CoBrandBar = () => {
  const [{ data }] = useAuthAxios({ url: `organizations/user` });
  const isMobile = useMedia("(max-width: 767px)");
  const [offsetHeight, setOffsetHeight] = useState(0);
  const topNav = document.getElementById("topNav");

  useEffect(() => {
    setOffsetHeight(isMobile ? 72 : topNav?.clientHeight || 0);
  }, [isMobile, topNav]);

  const website_url = data?.data?.url_website;
  const orgWebsiteUrl = protocolUrl(website_url);

  return (
    <Wrapper $offsetHeight={offsetHeight} py={1} px={1}>
      <Col xs={isMobile ? 12 : 6}>
        <Row $align="center">
          <Paragraph1>Brought to you by </Paragraph1>
          <Paragraph1 weight={700} $ml={1}>
            {data?.data?.file ? (
              <Image
                src={data?.data?.file}
                alt={data?.data?.title}
                height="30"
              />
            ) : (
              data?.data?.title && data?.data?.title
            )}
          </Paragraph1>
        </Row>
      </Col>
      {!isMobile && (
        <Col xs={6} $align="end" $justify="center">
          <Paragraph1>
            Want to go in person? Find a class time{" "}
            {orgWebsiteUrl ? (
              <StyledHyperLink
                href={orgWebsiteUrl}
                target="_blank"
                title="Link to in-person class times"
              >
                here
              </StyledHyperLink>
            ) : (
              <Link id={`cobrand-bar-here`} to={ROUTES.DASHBOARD}>
                here
              </Link>
            )}
          </Paragraph1>
        </Col>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Row)`
  background-color: ${(props) => props.theme.palette.neutralGrey};
  position: sticky;
  top: ${({ $offsetHeight }) => $offsetHeight}px;
  z-index: 9000;
`;

const StyledHyperLink = styled(Hyperlink)`
  text-decoration: underline;
`;

export default connect(null, {
  showFinderOnMobile: finderShowOnMobile,
})(memo(CoBrandBar));
