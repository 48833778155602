import { Fragment, memo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row } from "../_components/Layout";
import { Paragraph2 } from "./Typography";

const Breadcrumbs = ({ crumbs = [], color }) => {
  return crumbs.length ? (
    <BreadcrumbRow $align={"center"}>
      {crumbs.map((crumb, i) => {
        return (
          <Fragment key={crumb?.title || i}>
            {crumb?.link ? (
              <>
                <BreadcrumbLink to={crumb.link} color={color}>
                  <Paragraph2
                    mb={0}
                    $cursor="pointer"
                    color={color ? color : "primary"}
                  >
                    {crumb.title}
                  </Paragraph2>
                </BreadcrumbLink>
                <BreadcrumbSection color={color}>
                  &nbsp; &#62; &nbsp;
                </BreadcrumbSection>
              </>
            ) : (
              crumb?.title && (
                <Paragraph2 mb={0} color={color ? color : "primary"}>
                  {crumb.title}
                </Paragraph2>
              )
            )}
          </Fragment>
        );
      })}
    </BreadcrumbRow>
  ) : null;
};

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme, color }) =>
    color === "white"
      ? theme.palette.neutralWhite
      : color
      ? color
      : theme.palette.primaryBlue};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.color || props.theme.palette.primaryBlue};
  }
`;

const BreadcrumbRow = styled(Row)`
  align-items: center;
  margin-left: 0px;
  margin-bottom: 32px;
`;

const BreadcrumbSection = styled.span`
  text-decoration: none;
  color: ${({ theme, color }) => (color ? color : theme.palette.primaryBlue)};
  &:hover {
    text-decoration: none;
    color: ${(props) => props.color || props.theme.palette.primaryBlue};
  }
`;

export default memo(Breadcrumbs);
