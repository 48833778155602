import { memo } from "react";
import BarChart from "./BarChart";
import { Paragraph2 } from "../Typography";
import { Row, Col } from "..";
import styled from "styled-components";

// set this for the value for the fastest target amount
const fastestBarValue = 80;

const StyledRow = styled(Row)`
  margin-top: auto;
`;

const ChallengeBarChart = ({ challenge, title = true }) => {
  const shouldShow =
    challenge?.challenge?.status === "active" &&
    (challenge?.status === "active" || challenge?.status === "complete");

  if (!shouldShow) return null;

  const valueField = `${challenge?.challenge?.metric}_taken`;
  const isSingle = Boolean(challenge[valueField] === 1);
  let labelMetric = challenge?.challenge?.metric;
  if (isSingle && labelMetric === "classes") {
    labelMetric = "class";
  } else if (isSingle) {
    labelMetric = labelMetric.slice(0, -1);
  }
  const label = `${challenge[valueField]} ${labelMetric}`;

  let value = fastestBarValue;

  if (challenge?.challenge?.target === "highest") {
    let userAmt =
      challenge[valueField] === 0
        ? 0
        : challenge[valueField] / challenge?.challenge.target;
    value = Math.floor(userAmt * 100);
  } else if (challenge[valueField] === 0) {
    value = 0;
  } else if (challenge?.challenge?.method === "fastest") {
    value = Math.floor(
      (challenge[valueField] / challenge?.challenge?.target) * 100
    );
  }
  if (value > 100) value = 100;

  return (
    <StyledRow>
      <Col xs={12}>
        {title && (
          <Paragraph2 weight="700" $mt={6}>
            My Progress
          </Paragraph2>
        )}
        <BarChart alignLabel="flex-end" label={label} lineValue={value} />
      </Col>
    </StyledRow>
  );
};

export default memo(ChallengeBarChart);
