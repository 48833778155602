import { useState } from "react";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import ClassNavDropdown from "./ClassNavDropdown";
import { Row, Icon, Paragraph2 } from "../../_components";

const NavRow = ({ name, hover, label, showMore, linkTo, ...props }) => {
  const { pathname } = useLocation();
  let isActive = pathname === linkTo ? true : false;

  return (
    <Row $align={"center"} {...props} $py={1}>
      <Icon
        name={name}
        height="24px"
        width="24px"
        className="ml-2"
        fill={hover || isActive ? "liveRed" : "primary"}
      />
      <NavLabel hover={hover} isActive={isActive} cursor="pointer">
        {label}
      </NavLabel>

      {showMore && (
        <MobileShowMoreIconWrapper>
          <Icon
            name="chevron-right"
            height="16px"
            width="16px"
            fill={hover || isActive ? "liveRed" : "primary"}
          />
        </MobileShowMoreIconWrapper>
      )}
    </Row>
  );
};

const MobileShowMoreIconWrapper = styled.div`
  margin-left: auto;
`;

const ChildSwitch = ({
  type,
  name,
  hover,
  label,
  id,
  linkTo,
  linkLocation,
  showMore,
  ...props
}) => {
  switch (type) {
    case "dropdown":
      return (
        <ClassNavDropdown menu={name} {...props}>
          <NavRow name={name} hover={hover} label={label} />
        </ClassNavDropdown>
      );
    case "link":
      return (
        <Nav.Link as={Link} to={linkLocation || linkTo || "/"} className="pl-0">
          <NavRow
            name={name}
            hover={hover}
            label={label}
            id={id || null}
            linkTo={linkTo}
          />
        </Nav.Link>
      );
    case "line":
      return <hr className="m-0" />;
    default:
      return (
        <NavRow
          name={name}
          hover={hover}
          label={label}
          showMore={showMore}
          {...props}
        />
      );
  }
};

export const NavLink = ({
  name,
  label,
  id,
  linkTo,
  mobile,
  onClick,
  type,
  pss,
  fill,
  showMore,
  parentFrom,
  ...props
}) => {
  const [hover, setHover] = useState(false);
  const linkLocation = {
    pathname: linkTo,
    state: { from: parentFrom },
  };

  return (
    <NavItem
      as="li"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      mobile={mobile}
      $pss={pss}
      $fill={fill}
    >
      <ChildSwitch
        type={type}
        name={name}
        hover={hover}
        label={label}
        id={id || null}
        linkTo={linkTo}
        linkLocation={parentFrom ? linkLocation : linkTo}
        showMore={showMore}
        {...props}
      />
    </NavItem>
  );
};

const NavItem = styled(Nav.Item)`
  text-decoration: none;
  padding: ${({ $pss }) => $pss && ".5rem"};
  cursor: pointer;
  &:not(:first-child) {
    padding-top: ${(props) => (props.mobile ? "5px" : "")};
  }
  /* &:hover {
    path {
      stroke: red;
      fill: ${({ $fill }) => $fill && "#e00"};
    }
  } */
`;

const NavLabel = styled(Paragraph2)`
  color: ${(props) =>
    props.hover || props.isActive ? "#e00" : props.theme.palette.primaryBlue};
  font-weight: 700;
  &:not(:first-child) {
    padding-left: 5px;
  }
`;

export default NavLink;
