import { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import BrandPlaceHolder from "../BrandPlaceHolder";

const PreviewVideo = ({
  src,
  poster,
  customThumbnail,
  overlay,
  overlayTop,
  overlayCentered,
  ...rest
}) => {
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);

  const ref = useRef(null);
  useEffect(() => {
    if (src) {
      setVideo(src);
    }
  }, [src]);

  useEffect(() => {
    if (customThumbnail || poster) {
      setImage(customThumbnail ?? poster);
    }
  }, [poster, customThumbnail]);

  const onVideoMouseEnter = () => {
    if (ref) {
      ref.current.play();
    }
  };

  const onVideoMouseLeave = () => {
    if (ref) {
      ref.current.pause();
    }
  };

  const onErrorVideo = () => {
    setVideo(null);
  };

  const onErrorImage = () => setImage(null);

  return (
    <Wrapper $poster={image} onError={onErrorImage}>
      {video || image ? (
        <VideoWrapper
          ref={ref}
          onMouseEnter={onVideoMouseEnter}
          onMouseLeave={onVideoMouseLeave}
          onError={onErrorVideo}
          muted
          loop
          playsInline
          preload="metadata"
          kind="caption"
        >
          <source src={video} type="video/webm" />
        </VideoWrapper>
      ) : (
        <BrandPlaceHolder />
      )}
      {overlayTop && <Overlay $top={0}>{overlayTop}</Overlay>}
      {overlay && <Overlay {...rest}>{overlay}</Overlay>}
      {overlayCentered && <OverlayCentered>{overlayCentered}</OverlayCentered>}
    </Wrapper>
  );
};

const borderTopRadius = css`
  border-top-left-radius: ${({ theme }) =>
    theme.components.card.borderRadius}px;
  border-top-right-radius: ${({ theme }) =>
    theme.components.card.borderRadius}px;
`;

const Wrapper = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  position: relative;
  overflow: hidden;
  background-image: ${({ $poster }) => `url(${$poster})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ $poster }) => ($poster ? "black" : "transparent")};
  ${borderTopRadius}
`;

// this is set up for card use only
// update if the need needs to be elsewhere
const VideoWrapper = styled.video`
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  object-position: center;
  background: transparent;
  opacity: 0;
  transition: opacity 0.75s;
  ${borderTopRadius}
  &:hover {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  ${borderTopRadius}
  position: absolute;
  top: ${(props) => props.$top};
  right: ${(props) => props.$right};
  bottom: ${(props) => props.$bottom};
  left: ${(props) => props.$left};
  width: 100%;
  height: unset;
`;

const OverlayCentered = styled.div`
  ${borderTopRadius}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default PreviewVideo;
