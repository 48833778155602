import { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Avatar, { useGetAvatarSize } from "./Avatar";
import { Row, Col, Paragraph3 } from "../..";

const AvatarGroup = ({
  users,
  amt,
  amtShown = 5,
  text,
  size = "sm",
  ...rest
}) => {
  const { avatarSize, loading } = useGetAvatarSize(size);
  const [marginLeft, setMarginLeft] = useState();

  useLayoutEffect(() => {
    if (avatarSize) {
      const height = avatarSize?.replace(/\D/g, "");
      const type = avatarSize?.replace(/[^a-zA-Z]+/g, "");
      let ml = `${height * 0.2}${type}`;
      setMarginLeft(ml);
    }
  }, [avatarSize]);

  return !loading && marginLeft ? (
    <Row $align={"center"} pt={8}>
      <Col xs="auto">
        <Row $pb={1}>
          {users &&
            users
              ?.slice(0, amtShown)
              .map((user, i) => (
                <AvatarGroupItem
                  key={i}
                  $border
                  src={user?.profile?.file_avatar}
                  $ml={marginLeft}
                  {...rest}
                />
              ))}
        </Row>
      </Col>

      {text || amt ? (
        <Col>
          <Paragraph3 $overFlow="break-word" className="ml-2" weight={500}>
            {text
              ? text
              : `${amt} ${amt === 1 ? "person" : "people"} took this class`}
          </Paragraph3>
        </Col>
      ) : null}
    </Row>
  ) : null;
};

const AvatarGroupItem = styled(Avatar)`
  &:not(:first-child) {
    margin-left: -${({ $ml }) => $ml};
  }
`;

export default AvatarGroup;
