import React, { memo } from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

import theme from "../_theme";

import WhistlingGif from "../_media/images/ba_avatars/animated/Whistling.gif";

const PageLoader = ({ height = "100%", bmascot = false, bsmall = false }) => {
  return (
    <Wrapper $h={height}>
      {bmascot ? (
        <ImageWrapper $bsmall={bsmall}>
          <ImageGif
            alt="Loading..."
            src={typeof bmascot !== "boolean" ? bmascot : WhistlingGif}
          />
          <span>Loading...</span>
        </ImageWrapper>
      ) : (
        <>
          <SpinItem animation="border" role="status">
            <SpinItem animation="border" $primary />
            <span className="sr-only">Loading...</span>
          </SpinItem>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: ${({ $h }) => $h};
`;

const ImageWrapper = styled.div`
  max-width: ${({ $bsmall }) => ($bsmall ? "100px" : "200px")};
  text-align: center;
`;

const ImageGif = styled(Image)`
  width: 100%;
  position: relative;
`;

const SpinItem = styled(Spinner)`
  color: ${({ $primary }) =>
    $primary ? theme.palette.primaryBlue : theme.palette.baseTeal};
`;

export default memo(PageLoader);
