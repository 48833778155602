import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import { Paragraph2 } from "../../_components";

export const ClassMenuWrapper = styled(Dropdown.Menu)`
  padding-top: 0px;
  border-radius: 24px;
  border: none;
  box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.17);
  padding-bottom: 0;
  transform: translateY(75px) !important;
`;

export const Menu = styled(Dropdown.Menu)`
  position: absolute;
  padding-top: 0px;
  border-radius: 24px;
  border: none;
  box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.17);
  padding-bottom: 0;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export const MenuItem = styled(Dropdown.Item)`
  background: ${(props) =>
    props.active
      ? props.theme.palette.primaryBlue
      : props.theme.palette.neutralWhite} !important;
  padding-top: 12px;
  padding-bottom: 12px;
  max-height: 300px;

  &:first-child {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  &:last-child {
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  &:hover {
    background: ${(props) => props.theme.palette.primaryBlue} !important;
    color: ${(props) => props.theme.palette.neutralWhite} !important;
  }
`;

export const ProfileMenu = styled.div`
  border-radius: 24px;
  border: none;
  box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.17);
  padding-bottom: 0;
  height: max-content;
  z-index: 9999;
  background-color: white;
  position: absolute;
  right: 36px;
  top: 81px;
  padding-bottom: 20px;
`;

export const ProfileMenuItem = styled.div`
  background: ${(props) =>
    props.active
      ? props.theme.palette.primaryBlue
      : props.theme.palette.neutralWhite} !important;
  padding: 12px;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  &:last-child {
    border-bottom-left-radius: 24px !important;
    border-bottom-right-radius: 24px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  &:hover {
    background: ${(props) => props.theme.palette.primaryBlue} !important;
    color: ${(props) => props.theme.palette.neutralWhite} !important;
  }
`;

export const LinkText = styled(Paragraph2)`
  color: ${({ theme, $active }) =>
    $active
      ? theme.palette.neutralWhite
      : theme.palette.primaryBlue} !important;
  font-weight: 700;
  &:hover,
  ${MenuItem}:hover & {
    color: ${({ theme }) => theme.palette.neutralWhite} !important;
  }
  ${ProfileMenuItem}:hover & {
    color: ${({ theme }) => theme.palette.neutralWhite} !important;
  }
`;
