import "./init"; // prevents braintree-web-drop-in from breaking settings page https://stackoverflow.com/a/73208485
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { isIOS } from "react-device-detect";
import { ThemeProvider } from "styled-components";

import store from "./_redux/store";
import { BrazeProvider } from "./_context/BrazeContext";
import Routes from "./_routes";
import theme from "./_theme";
import GlobalStyle from "./_theme/GlobalStyle";

import "./_styles/app.scss";
import { isDatadogEnvironment } from "./constants";

if (isDatadogEnvironment) {
  const sessionReplaySampleRate = import.meta.env.VITE_APP_ENV === "remote-dev-env" ? 100 : 20;

  datadogRum.init({
    applicationId: import.meta.env.VITE_APP_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_APP_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.VITE_APP_DATADOG_SITE,
    service: "burnalong-react-web-experience",
    env: import.meta.env.VITE_APP_ENV,
    // TODO: Specify a version number to identify the deployed version of your application in Datadog
    version: import.meta.env.VITE_APP_RELEASE,
    sessionSampleRate: 100,
    sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

// https://github.com/facebook/react/issues/11538#issuecomment-417504600
// Resolves issue with React and Google Translate inserting <font> tags into the DOM and form elements
if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error("Cannot remove a child from a different parent", child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent",
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

function App() {
  // Note: iOS web viewport workaround
  const setVh = () => {
    if (!isIOS) return;
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    window.addEventListener("load", setVh);
    window.addEventListener("resize", setVh);

    return () => {
      window.removeEventListener("load", setVh);
      window.removeEventListener("resize", setVh);
    };
  });

  return (
    <>
      <GlobalStyle $theme={theme} />
      <React.StrictMode>
        <Provider store={store}>
          <Helmet titleTemplate="Burnalong | %s" />
          <ThemeProvider theme={theme}>
            <BrazeProvider>
              <Routes />
            </BrazeProvider>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
