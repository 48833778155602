import { createContext, useContext } from "react";
import * as braze from "@braze/web-sdk";

import { brazeKeys } from "../constants";

const initialized = braze.initialize(brazeKeys.API, {
  baseUrl: brazeKeys.SDK,
});

if (initialized) {
  braze.automaticallyShowInAppMessages();
  braze.openSession();
}

const BrazeContext = createContext(braze);

const BrazeProvider = ({ children }) => {
  return (
    <BrazeContext.Provider value={braze}>{children}</BrazeContext.Provider>
  );
};

const useBraze = () => {
  const context = useContext(BrazeContext);

  if (context === undefined) {
    throw new Error("useBraze must be used within a BrazeProvider");
  }

  return context;
};

export { BrazeProvider, useBraze };
