import { memo } from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { Avatar, Paragraph1, Paragraph3, Col, Row } from "../../_components";

import { StarRating } from "../../_components/ui";

const CourseReviewCard = ({ review }) => {
  return (
    <ReviewCardColumn xs={12} md={8} xl={6}>
      <Row>
        <Col $pl={0}>
          <Row $align={"center"}>
            <Avatar
              src={
                review?.user?.profile?.show_photo
                  ? review?.user?.profile?.file_avatar
                  : null
              }
              size="sm"
            />
            <Paragraph1 $ml={4} weight={700}>
              {review?.user?.profile?.show_nickname
                ? `${review?.user?.profile?.nickname || ""}`
                : "A Member"}
            </Paragraph1>
          </Row>
        </Col>
        <Col $align={"flex-end"} $justify={"center"}>
          <StarRating rating={review.stars} />
        </Col>
      </Row>
      <Col $justify={"flex-end"} $pl={0}>
        {review?.body && (
          <Row>
            <Paragraph1 $mt={4}>{review.body}</Paragraph1>
          </Row>
        )}
        <Row>
          <Paragraph3 $mt={0.5} $pb={3} color="grey3">
            <Moment fromNow withTitle>
              {review?.date_created}
            </Moment>
          </Paragraph3>
        </Row>
        {review.replies && review.replies.length > 0 && (
          <ReviewReplyColumn>
            <Col $pl={0}>
              <Row $align={"center"}>
                <Avatar
                  src={review?.course?.instructor?.profile?.file_avatar || null}
                  size="sm"
                  alt={review?.course?.instructor?.profile?.nickname}
                />
                <Paragraph1 $ml={4} weight={700}>
                  {review?.course?.instructor?.first_name || ""}{" "}
                  {review?.course?.instructor?.last_name || ""}
                </Paragraph1>
              </Row>
              <Row>
                <Paragraph1 $mt={4}>
                  {review?.replies[0]?.body || ""}
                </Paragraph1>
              </Row>
              {review?.replies &&
                review.replies[0] &&
                review.replies[0].date_created && (
                  <Row>
                    <Paragraph3
                      $align={"flex-end"}
                      $mt={0.5}
                      $pb={3}
                      color={"grey3"}
                    >
                      <Moment fromNow withTitle>
                        {review?.replies[0]?.date_created}
                      </Moment>
                    </Paragraph3>
                  </Row>
                )}
            </Col>
          </ReviewReplyColumn>
        )}
      </Col>
    </ReviewCardColumn>
  );
};

const ReviewCardColumn = styled(Col)`
  padding: 24px;
  background: ${(props) => props.theme.palette.neutralWhite};
  border-radius: 24px;
  width: 100%;
  margin-bottom: 24px;
`;

const ReviewReplyColumn = styled(Col)`
  border-left: 4px solid ${(props) => props.theme.palette.neutralGrey};
`;

export default memo(CourseReviewCard);
