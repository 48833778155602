import React from "react";
import styled from "styled-components";

import Modal from "../../_components/Modal";
import { Hyperlink } from "../../_components/ui";
import { Paragraph1, Paragraph2 } from "../../_components";
import { useConsents } from "../../_context/ConsentsContext";

const StyledHyperLink = styled(Hyperlink)`
  text-decoration: underline;
`;

const ForceConsentModal = ({ consentToForce }) => {
  const { updateConsents, loading } = useConsents();

  if (loading) return null;

  const onSubmit = async (consent_choice) => {
    await updateConsents([
      {
        consent_id: consentToForce.id,
        consent_choice,
      },
    ]);
  };

  const onCancel = async () => {
    await onSubmit(false);
  };

  const onAccept = async () => {
    await onSubmit(true);
  };

  return (
    <Modal
      show={true}
      title="Welcome Back to Burnalong!"
      titleAlign="center"
      onAccept={onAccept}
      onCancel={onCancel}
      acceptText="I consent"
      cancelText="I do not consent"
    >
      <Paragraph1 $mb={5} weight={700}>
        We are always working to protect your privacy. We have made changes to
        our Privacy Policy and are asking you to provide consent to sharing data
        with your sponsoring organization.
      </Paragraph1>
      <Paragraph2 $mb={5}>{consentToForce?.consent_text}</Paragraph2>
      <Paragraph2>
        Want more information about your privacy? Check out our{" "}
        <StyledHyperLink
          href="https://www.burnalong.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          title="Privacy Policy"
        >
          Privacy Policy
        </StyledHyperLink>
        {"."}
      </Paragraph2>
    </Modal>
  );
};

export default ForceConsentModal;
