import { memo } from "react";
import styled from "styled-components";
import { Paragraph2 } from "../Typography";
import { Row } from "../Layout";
import { convertHexToRGBA } from "../../_utils/color";

const BarChart = ({
  color,
  noRadius,
  label,
  labelRight,
  lineValue,
  mt,
  alignLabel = "space-between",
  id,
}) => {
  return (
    <BarChartContainer id={id}>
      <Row $justify={alignLabel}>
        <Paragraph2 color="grey2" weight="500">
          {label}
        </Paragraph2>
        {labelRight && (
          <Paragraph2 color="grey2" weight="500">
            {labelRight}
          </Paragraph2>
        )}
      </Row>
      <BarChartContent $color={color} $noRadius={noRadius} $mt={mt}>
        <BarChartLine value={lineValue} $color={color} $noRadius={noRadius} />
      </BarChartContent>
    </BarChartContainer>
  );
};

export const BarChartContainer = styled.div``;

export const BarChartContent = styled.div`
  background: ${({ theme, $color }) =>
    convertHexToRGBA($color ? $color : theme.palette.level1Green, 20)};
  width: 100%;
  height: 16px;
  border-radius: ${({ $noRadius }) => !$noRadius && "128px"};
  margin-top: ${({ theme, $mt }) =>
    $mt ? $mt : theme.components.card.spacing}px;
`;

export const BarChartLine = styled.div`
  background: ${({ theme, $color }) =>
    $color ? $color : theme.palette.level1Green};
  height: 16px;
  width: ${({ value }) => value}%;
  border-radius: ${({ $noRadius }) => !$noRadius && "128px"};
  margin-right: auto;
`;

export default memo(BarChart);
