import { memo, useRef, useState, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import { noMargin } from "../../../_theme/general";
import Icon from "../../Icon";

export const useGetAvatarSize = (size, height, iconHeight) => {
  const [avatarSize, setAvSize] = useState();
  const [iconSize, setIconSize] = useState("3em");
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (height) {
      setAvSize(height);
      setIconSize(iconHeight);
    } else if (size) {
      switch (size) {
        case "sm":
          setAvSize("3rem");
          setIconSize("1.5em");
          break;
        case "md":
          setAvSize("4rem");
          setIconSize("2em");
          break;
        case "lg":
          setAvSize("6rem");
          setIconSize("3em");
          break;
        case "xlg":
          setAvSize("8rem");
          setIconSize("4em");
          break;
        default:
          setAvSize("3rem");
          setIconSize("1.5em");
      }
    }
    setLoading(false);
  }, [size, height, iconHeight]);
  return { avatarSize, iconSize, loading };
};

const Avatar = ({
  src,
  onClick,
  size = "sm",
  height,
  iconHeight,
  border,
  instructor,
  white,
  partner,
  bg = "grey1",
  center = false,
  ...props
}) => {
  const ref = useRef(null);
  const [srcImage, setSrcImage] = useState(null);
  const { avatarSize, iconSize, loading } = useGetAvatarSize(
    size,
    height,
    iconHeight
  );
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (src) {
      setSrcImage(src);
    }
  }, [src]);

  useEffect(() => {
    if (instructor) {
      setHide(!Boolean(srcImage));
    }
  }, [instructor, srcImage]);

  const handleOnError = () => {
    if (instructor) setHide(true);
    setSrcImage(null);
  };

  return (
    !loading && (
      <Wrapper
        onClick={onClick}
        $center={center}
        $border={border}
        $height={avatarSize}
        $hide={hide}
        $partner={partner}
        {...props}
      >
        {srcImage ? (
          <AvatarImage
            src={srcImage}
            ref={ref}
            onError={handleOnError}
            alt=""
            roundedCircle={!partner}
            $partner={partner}
          />
        ) : (
          <DefaultPlaceHolder $bg={white ? "teal" : bg}>
            <Icon
              name="logo"
              fill={white ? "white" : "primary"}
              height={iconSize}
              width={iconSize}
            />
          </DefaultPlaceHolder>
        )}
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  ${noMargin}
  margin: ${({ $center }) => ($center ? "auto" : "unset")};
  position: relative;
  display: ${({ $hide }) => ($hide ? "none" : "inline-block")};
  width: ${(props) => props.$height};
  height: ${(props) => props.$height};
  border-radius: ${({ $partner }) => ($partner ? "0" : "50%")};
  overflow: hidden;
  border: ${(props) => (props.$border ? "2px solid white" : "")};
`;

const AvatarImage = styled(Image)`
  width: 100%;
  height: 100%;
  display: block;
  object-position: center;
  object-fit: ${({ $partner }) => ($partner ? "contain" : "cover")};
`;

const DefaultPlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, $bg }) =>
    $bg === "grey1" ? theme.palette.neutralGrey : theme.palette.baseTeal};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default memo(Avatar);
