import styled from "styled-components";
import {
  Row as BRow,
  Col as BCol,
  Container as BContainer,
} from "react-bootstrap";
import { noMargin, noPadding } from "../../_theme/general";
import React from "react";

export const Row = ({ ...props }) => <RowWrap {...props} />;

const RowWrap = styled(BRow)`
  margin: 0;
  padding: 0;
  ${noMargin}
  ${noPadding}
  align-items: ${({ align, $align }) => align || $align};
  justify-content: ${({ justify, $justify }) => justify || $justify};
  flex-direction: ${({ $direction }) => $direction};
  flex-wrap: ${({ nowrap, $nowrap }) =>
    $nowrap || nowrap ? "nowrap!important" : "wrap"};
  width: ${({ $width }) => $width};
  cursor: ${({ cursor, $cursor }) => cursor || $cursor};
  background-color: ${({ bg, $bg }) => bg || $bg};
  gap: ${({ gap, $gap }) => gap || $gap || 0}px;
`;

export const Col = ({ ...props }) => <ColWrap {...props} />;

const ColWrap = styled(BCol)`
  ${noMargin}
  ${noPadding}
  align-items: ${({ align, $align }) => align || $align};
  align-self: ${({ alignSelf, $alignSelf }) => alignSelf || $alignSelf};
  justify-content: ${({ justify, $justify }) => justify || $justify};
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => $width};
  background: ${({ $bg }) => $bg};
  gap: ${({ $gap }) => $gap || 0}px;
  border: ${({ $border }) => $border || "none"};
`;

export const Container = ({ ...props }) => <ConWrap {...props} />;

const ConWrap = styled(BContainer)`
  padding: ${({ fluid }) => fluid && 0};
  ${noMargin}
  ${noPadding}
  display: flex;
  flex-direction: ${(props) =>
    props.$direction ? props.$direction : "column"};
  background-color: ${(props) =>
    props.bg ? props.bg : props.theme.palette.white001};
  max-width: ${(props) =>
    props.fluid ? "none" : props.maxWidth ? props.maxWidth : ""};
`;
