import styled from "styled-components";

import theme from "../../_theme";

import { Col, Row } from "../Layout";
import { Paragraph2 } from "../Typography";
import Avatar from "../../_components/ui/Avatars/Avatar";
import Icon from "../../_components/Icon";
import { BadgeText } from "../Badge";
import OptionMenu from "../OptionMenu";
import { Paragraph3 } from "..";
import { StyledCard } from "./CardBase";

const ContactCard = ({
  contact,
  selected,
  status = undefined,
  options = false,
  noThirdCol,
  ...props
}) => {
  return (
    <StyledCardBase image="None" forceRadius selected={selected} {...props}>
      <Row $align="center">
        <Col xs="auto" $px={0} $pr={1}>
          <Avatar src={contact?.profile?.file_avatar} />
        </Col>
        <ContactInfoCol xs sm={noThirdCol ? 9 : 8} tabIndex={0}>
          <StyledParagraph2
            pb={1}
            color={selected ? "white" : "blue"}
            weight="700"
          >
            {`${contact?.first_name || ""} ${contact?.last_name || ""}`}
          </StyledParagraph2>
          <StyledParagraph3 color={selected ? "white" : "blue"}>
            {contact?.email}
          </StyledParagraph3>
          {status && (
            <StyledBadge title={status} textColor="black" $status={status} />
          )}
        </ContactInfoCol>
        {noThirdCol ? null : (
          <Col xs="auto" sm={1} $align="flex-start" $alignSelf="flex-start">
            {selected && (
              <Icon name="check-circle" width="1.5em" fill={"white"} />
            )}
            {options && <OptionMenu label="contact-info">{options}</OptionMenu>}
          </Col>
        )}
      </Row>
    </StyledCardBase>
  );
};

const StyledCardBase = styled(StyledCard)`
  background-color: ${(props) =>
    props.selected
      ? props.theme.palette.primaryBlue
      : props.theme.palette.neutralWhite};
  padding: 1.5rem;
  justify-content: center;
  margin: ${(props) => props.slider && "20px"};
`;

const StyledParagraph2 = styled(Paragraph2)`
  max-width: 100%;
  overflow-wrap: break-word;
`;

const ContactInfoCol = styled(Col)`
  margin-right: auto;
`;

const StyledParagraph3 = styled(Paragraph3)`
  max-width: 100%;
  overflow-wrap: break-word;
`;

const StyledBadge = styled(BadgeText)`
  margin-top: auto;
  text-transform: capitalize;
  background-color: ${({ $status }) =>
    $status === "pending"
      ? theme.palette.baseYellow
      : $status === "active"
      ? theme.palette.hoverBaseGreen
      : theme.palette.neutralGrey};
`;

export default ContactCard;
