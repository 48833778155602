import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Col } from "../Layout";
import { Controller } from "react-hook-form";
import FormGroup from "./FormGroup";
import { Paragraph2 } from "../Typography";
import Icon from "../Icon";
import { noMargin } from "../../_theme/general";

const CheckboxBase = React.forwardRef(
  (
    {
      label,
      name,
      onChange,
      defaultValue,
      error,
      ariaLabel,
      id,
      $align,
      value,
    },
    forwardedRef
  ) => {
    const [checked, setChecked] = useState(
      defaultValue === true ||
        (defaultValue && defaultValue?.toLowerCase() === "true")
        ? true
        : false
    );

    useEffect(() => {
      setChecked(
        value === true || (value && value?.toLowerCase() === "true")
          ? true
          : false
      );
    }, [value]);

    useEffect(() => {
      if (onChange) {
        onChange(checked);
      }
      // eslint-disable-next-line
    }, [checked]);

    const handleOnClick = () => setChecked(!checked);
    return (
      <Row $align={$align} $px={0}>
        <Col xs="auto">
          <input
            style={{ display: "none" }}
            ref={forwardedRef}
            type="checkbox"
            name={name}
            label={label || null}
            aria-label={ariaLabel || name}
            value={checked}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
          <CheckBoxContainer
            id={id || null}
            onClick={handleOnClick}
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleOnClick();
              }
            }}
            aria-label={ariaLabel || label || null}
            $checked={checked}
            $error={error}
            data-testid={id}
          >
            {checked && (
              <Icon name="check" fill="white" height="18px" width="18px" />
            )}
          </CheckBoxContainer>
        </Col>
        <Col>
          <Paragraph2 as="label" $pl={3} $mb={0}>
            {label}
          </Paragraph2>
        </Col>
      </Row>
    );
  }
);

const FormCheckbox = ({
  control,
  label,
  name,
  helperText,
  disabled,
  required,
  error,
  errors,
  register,
  ref,
  options,
  defaultValue = false,
  type = "checkbox",
  ariaLabel,
  onChange,
  id,
  $align = "center",
  ...rest
}) => {
  return (
    <FormGroup
      name={name}
      helperText={helperText}
      errors={errors}
      error={error}
      label={Boolean(label && type !== "checkbox") ? label : null}
      {...rest}
    >
      {type === "checkbox" &&
        (control ? (
          <Controller
            id={name}
            aria-label={label}
            name={name}
            type={type}
            defaultValue={defaultValue}
            as={
              <CheckboxBase
                ref={ref}
                type={type}
                defaultValue={defaultValue}
                label={label}
                required={required}
                error={errors || error}
                $align={$align}
                onChange={(e) => onChange && onChange(e)}
              />
            }
            control={control}
            {...rest}
          />
        ) : (
          <CheckboxBase
            id={id || null}
            ref={ref}
            type={type}
            defaultValue={defaultValue}
            label={label}
            required={required}
            ariaLabel={ariaLabel}
            onChange={(e) => onChange && onChange(e)}
          />
        ))}

      {type === "radio" && (
        <RadiobuttonBase
          options={options}
          register={register}
          name={name}
          defaultValue={defaultValue}
          error={error}
          {...rest}
        />
      )}
    </FormGroup>
  );
};

const RadiobuttonBase = ({
  options,
  name,
  register,
  defaultValue,
  rules,
  error,
  inline,
  callback,
  ...rest
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChecked = (item) => {
    setChecked(item);
    callback && callback(item.value);
  };

  return (
    <RadioWrapper $inline={inline}>
      {options?.map((item, i) => (
        <Row $align="center" $px={0} $mt={!inline && i !== 0 && 2}>
          <input
            ref={register(rules)}
            aria-label={name || ""}
            type="radio"
            checked={checked?.value === item?.value}
            value={item?.value}
            name={name}
            style={{ display: "none" }}
          />
          <CheckBoxContainer
            id={item.id || null}
            tabIndex={0}
            onClick={() => handleChecked(item)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleChecked(item);
              }
            }}
            $checked={checked?.value === item?.value}
            $error={error}
            $radio
            $ml={inline && i !== 0 && 2}
            {...rest}
          >
            {checked && (
              <Icon name="check" fill="white" height="14px" width="14px" />
            )}
          </CheckBoxContainer>
          <Paragraph2 as="label" pl={3} $mb={0}>
            {item?.label}
          </Paragraph2>
        </Row>
      ))}
    </RadioWrapper>
  );
};

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: ${({ theme, $error }) =>
    `1px solid ${
      $error ? theme.palette.liveRed : theme.palette.primaryBlue
    }`} !important;
  border-radius: ${({ $radio }) => ($radio ? "50px" : "8px")};
  cursor: pointer;
  background-color: ${({ theme, $checked }) =>
    $checked ? theme.palette.primaryBlue : ""} !important;
  ${noMargin}
`;
const RadioWrapper = styled.div`
  display: flex;
  flex-direction: ${({ $inline }) => ($inline ? "row" : "column")};
  margin-top: 0.25rem;
`;

const Checkbox = memo(FormCheckbox);

const Radiobutton = ({ ...rest }) => {
  return <Checkbox {...rest} type="radio" />;
};

const Toggle = ({ ...rest }) => {
  return <Checkbox {...rest} type="switch" />;
};

export { Checkbox, Radiobutton, Toggle };
