import authAxios from "../_utils/authAxios";
import { alertEvent } from "../_redux/actions/notifications/event";
import { logDismissed } from "../_redux/actions/notifications/schedule";
import moment from "moment";

export const pingActiveUser = () => authAxios("common/watchdog");

export const getNotificationSchedule = (
  dispatch,
  userId,
  dismissed,
  withinTime = 15
) => {
  authAxios(`invites/user`).then((res) => {
    const data = res.data.items;

    const events = data
      .map(({ event, id }) => ({
        id,
        start: event.date_schedule,
        host: event.host,
        slug: event.url_slug,
        type: event.type,
      }))
      .filter(({ type, id, start, host }) => {
        return (
          userId &&
          type === "group" &&
          !(id in dismissed) &&
          moment(start).isAfter(new Date()) &&
          moment().diff(start, "minutes") * -1 <= withinTime &&
          host !== userId
        );
      });

    if (events.length > 0) {
      dispatch(
        alertEvent(
          "You are invited to a group workout!",
          "Upcoming",
          "Click here to see details!",
          "/schedule"
        )
      );
      const newIds = events.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: "dismissed" }),
        {}
      );
      dispatch(logDismissed({ ...dismissed, ...newIds }));
    }
  });
};
