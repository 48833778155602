import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "../Icon/Icon";
import { noMargin, noPadding } from "../../_theme/general";

export const BUTTON_TYPE = {
  filled: "filled",
  outline: "outline",
  text: "text",
};

const StyledButton = styled.button`
  padding-left: ${({ theme, leftIcon, rightIcon, large, padding }) =>
    padding
      ? padding
      : theme.components.button[
          !large && (leftIcon || rightIcon) ? "imagePadding" : "paddingLeft"
        ]};
  padding-right: ${({ theme, leftIcon, rightIcon, large, padding }) =>
    padding
      ? padding
      : theme.components.button[
          !large && (leftIcon || rightIcon) ? "imagePadding" : "paddingRight"
        ]};

  width: ${({ fullWidth }) => (fullWidth ? "100%" : "max-content")};
  border-width: ${({ theme }) => theme.components.button.borderWidth};
  font-size: ${({ theme }) => theme.components.button.fontSize};
  line-height: ${({ theme }) => theme.components.button.lineHeight};
  border-style: ${({ theme }) => theme.components.button.borderStyle};
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: ${({ margin }) => margin && margin + "px"};
  ${noMargin}
  ${noPadding}
  justify-content: center;
  align-self: ${({ $alignSelf }) => $alignSelf};

  border-radius: ${({ theme, large }) => {
    if (large) {
      return theme.components.button.borderRadiusLarge;
    } else {
      return theme.components.button.borderRadiusNormal;
    }
  }};

  height: ${({ theme, large, small, height }) => {
    if (height) {
      return height;
    }
    if (large) {
      return theme.components.button.heightLarge;
    } else if (small) {
      return;
    } else {
      return theme.components.button.heightNormal;
    }
  }};

  color: ${({ theme, $bType, dark }) => {
    if ($bType === BUTTON_TYPE.filled) {
      if (dark) {
        return theme.palette.neutralWhite;
      } else {
        return theme.palette.primaryBlue;
      }
    } else if ($bType === BUTTON_TYPE.outline) {
      if (dark) {
        return theme.palette.primaryBlue;
      } else {
        return theme.palette.neutralWhite;
      }
    } else if ($bType === BUTTON_TYPE.text) {
      if (dark) {
        return theme.palette.primaryBlue;
      } else {
        return theme.palette.neutralWhite;
      }
    }
  }};

  background: ${({ theme, $bType, dark }) => {
    if ($bType === BUTTON_TYPE.filled) {
      if (dark) {
        return theme.palette.primaryBlue;
      } else {
        return theme.palette.neutralWhite;
      }
    } else if ($bType === BUTTON_TYPE.outline) {
      return "transparent";
    } else if ($bType === BUTTON_TYPE.text) {
      return "transparent";
    }
  }};

  border-color: ${({ theme, $bType, dark }) => {
    if ($bType === BUTTON_TYPE.filled || $bType === BUTTON_TYPE.text) {
      return "transparent";
    } else if ($bType === BUTTON_TYPE.outline) {
      if (dark) {
        return theme.palette.primaryBlue;
      } else {
        return theme.palette.neutralWhite;
      }
    }
  }};

  &:hover {
    background: ${({ theme, $bType, dark, disabled }) => {
      if ($bType === BUTTON_TYPE.filled && !disabled) {
        if (dark) {
          return theme.palette.hoverPrimary;
        } else {
          return theme.palette.neutralWhite;
        }
      } else if ($bType === BUTTON_TYPE.outline && !disabled) {
        return theme.palette[`hoverOutline${dark ? "Dark" : "Light"}`];
      }
    }};

    color: ${({ theme, $bType, dark, disabled }) => {
      if ($bType === BUTTON_TYPE.text && !disabled) {
        if (dark) {
          return theme.palette.hoverPrimary;
        } else {
          return theme.palette.hoverTextLight;
        }
      }
    }};
  }

  &:disabled {
    opacity: ${({ dark }) => (dark ? 0.4 : 0.2)};
  }

  &:active {
    background: ${({ theme, $bType, dark, disabled }) => {
      if ($bType === BUTTON_TYPE.filled && !disabled) {
        if (dark) {
          return theme.palette.pressedDark;
        } else {
          return theme.palette.pressedLight;
        }
      } else if ($bType === BUTTON_TYPE.outline && !disabled) {
        if (dark) {
          return theme.palette.pressedOutlineDark;
        } else {
          return theme.palette.pressedLight;
        }
      }
    }};
  }

  > svg {
    margin-left: ${({ leftIcon, rightIcon, $bType, title }) =>
      $bType === "text" || !title || (leftIcon && !rightIcon) ? 0 : 8}px;

    margin-right: ${({ leftIcon, rightIcon, $bType, title }) =>
      $bType === "text" || !title || (!leftIcon && rightIcon) ? 0 : 8}px;
  }
`;

export const Button = ({
  type = "",
  children = null,
  title = "",
  dark = true,
  large = false,
  small = false,
  bType = BUTTON_TYPE.filled,
  leftIcon = null,
  rightIcon = null,
  iconSize = 24,
  disabled = false,
  fullWidth = false,
  iconColor,
  margin,
  padding,
  height,
  id,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  let icColor = iconColor;
  if (!iconColor) {
    icColor =
      dark && bType === BUTTON_TYPE.filled
        ? themeContext.palette.neutralWhite
        : themeContext.palette.primaryBlue;
  }
  return (
    <StyledButton
      $bType={bType}
      dark={dark}
      large={large}
      small={small}
      height={height}
      margin={margin}
      padding={padding}
      disabled={disabled}
      fullWidth={fullWidth}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      type={type}
      title={title}
      id={id?.replace(/[^0-9a-zA-Z]/g, "-") || null}
      {...props}
    >
      {leftIcon && (
        <IconInButton
          fill={icColor}
          name={leftIcon}
          height={iconSize}
          width={iconSize}
        />
      )}
      {children}
      {rightIcon && (
        <IconInButton
          $right
          name={rightIcon}
          height={iconSize}
          width={iconSize}
          $iconColor={icColor}
        />
      )}
    </StyledButton>
  );
};

const IconInButton = styled(Icon)`
  margin-left: ${({ $right }) => $right && "10px !important"};
  margin-right: ${({ $right }) => !$right && "10px !important"};
`;

export default memo(Button);

export const ButtonLink = ({ to, component, ...rest }) => {
  return (
    <Link to={to} component={component}>
      <Button {...rest} />
    </Link>
  );
};
