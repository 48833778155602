import moment from "moment-timezone";
import styled from "styled-components";

import Modal from "./Modal";
import { Button, Col, Heading5, Heading6, Icon, Row } from "./index";

import { useBraze } from "../_context/BrazeContext";
import { utcDateToDisplay } from "../_utils";

const PersonalCalendarModal = ({ course, show, setShow }) => {
  const braze = useBraze();

  if (!show) {
    return null;
  }

  // Currently where this modal lives, there are click handlers
  // higher up in the component hierarchy we don't want to trigger
  // when interacting with this
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleClose = (e) => {
    setShow(false);
  };

  const handleAddToCalendar = () => {
    const timeZone = moment(course.schedule.date_schedule)
      .tz(moment.tz.guess())
      .format("z");

    const startDate = utcDateToDisplay(course.schedule.date_schedule);

    const eventData = {
      className: course.title,
      instructor: `${course.instructor.first_name} ${course.instructor.last_name}`,
      classStartDate: startDate.format("dddd, MMMM Do, YYYY"),
      classStartTime: utcDateToDisplay(course.schedule.date_schedule)
        .format("hh:mm A z")
        .replace("UTC", timeZone),
      classStartIso: startDate.format(),
      classEndIso: startDate.add(course.duration_estimate, "minutes").format(),
      classDuration: `${course.duration_estimate} minutes`,
      classUrl: `${window.location.protocol}//${window.location.host}/class/${course.id}/live/${course.schedule.id}`,
      classType: course.type,
    };

    braze.logCustomEvent("class_scheduled", eventData);
    handleClose();
  };

  const modalTitle = (
    <Col $px={0}>
      <Heading6 weight="700" $pl={8} $pt={4} $textAlign="center">
        Saved to your Burnalong Schedule!
      </Heading6>
    </Col>
  );

  return (
    <div onClick={stopPropagation}>
      <Modal show={show} onHide={handleClose} title={modalTitle}>
        <FlexContainer>
          <Heading5 weight="700" $textAlign="center">
            Never miss a class! Add to your personal calendar.
          </Heading5>

          <Row $align="center" $justify="center">
            <Icon name={"calendar"} width={80} height={72} />
          </Row>

          <Row>
            <AddToCalendarButton onClick={handleAddToCalendar}>
              Add to Calendar
            </AddToCalendarButton>
          </Row>
        </FlexContainer>
        <Row $align="center" $justify="center">
          <NoThanksButton onClick={handleClose}>No Thanks</NoThanksButton>
        </Row>
      </Modal>
    </div>
  );
};

const NoThanksButton = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
`;

const AddToCalendarButton = styled(Button)`
  flex: 1;
  width: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

export default PersonalCalendarModal;
