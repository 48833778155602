import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import Icon from "../../Icon";
import { CardOverlay } from "../CardBase";
import { Heading5 } from "../../Typography";
import { Row } from "../../Layout";

const CourseStatusOverlay = ({ data, enrolled, scheduled }) => {
  const missedClassBuffer = 30; //minutes
  const [missed, setMissed] = useState();
  const [completed, setCompleted] = useState();

  useEffect(() => {
    const isComplete =
      (data?.status === "inactive" || data?.status === "complete") && enrolled;
    setCompleted(isComplete);
  }, [data?.status, enrolled]);

  useEffect(() => {
    const isMissed =
      !completed &&
      data?.date_scheduled &&
      enrolled &&
      moment(scheduled).isBefore(
        moment().subtract(missedClassBuffer, "minutes")
      );
    setMissed(isMissed);
  }, [completed, data?.date_scheduled, missedClassBuffer, enrolled, scheduled]);

  return missed || completed ? (
    <CardOverlay>
      <RowWrapper>
        <Icon
          name={missed ? "times-circle" : "check-circle"}
          fill={missed && "red"}
        />
        <Heading5 color={missed ? "red" : "blue"} weight="700" $ml={4}>
          {missed ? "Missed" : "Completed"}
        </Heading5>
      </RowWrapper>
    </CardOverlay>
  ) : null;
};

const RowWrapper = styled(Row)`
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
`;

export default CourseStatusOverlay;
