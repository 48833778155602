import { memo } from "react";
import styled from "styled-components";
import { BadgeText } from "../../Badge";
import getID from "../../../_utils/getID";

const DatePill = ({ data }) => {
  const textColor =
    data?.bg === "#e00"
      ? "white"
      : data?.missed || data?.complete
      ? "grey2"
      : "blue";

  return (
    <Wrapper id={`date-pill-${getID(data?.date)}`}>
      <BadgeText
        size="md"
        bg={data?.bg ? data.bg : "white"}
        title={data?.date}
        textColor={textColor}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  margin: 10px;
`;

export default memo(DatePill);
